import React from 'react';
import '../../scss/index.scss';
import DefaultHeader from '../../components/DefaultHeader/DefaultHeader';
import { Launch16, Launch20, PlayFilledAlt20 } from '@carbon/icons-react';
import {
  Tag, Button, Link
} from "carbon-components-react";
import {
  LeadSpace,
  Card
} from '@carbon/ibmdotcom-react';
import { card01, card02, card03, cta01, cta02, cta03 } from './cards-content'

const primeiroAcesso = <div className="footer"><small>Primeiro acesso? Cadatre-se no <Link href="https://students.yourlearning.ibm.com" target="_blank" rel="noreferrer" renderIcon={Launch16} size="md">SkillsBuild</Link></small></div>

const openInNewTab = url => {
  // 👇️ setting target to _blank with window.open
  window.open(url, '_blank', 'noopener,noreferrer');
};

function VideoButton(props) {
  let url = "https://youtu.be/"+ props.youtube_id
  return <Button className="top right" kind="tertiary" 
  renderIcon={PlayFilledAlt20} 
  onClick={() => openInNewTab(url)}>Veja o video antes de começar</Button>
}

function SkillsBuildButton(props) {
  let url = "https://ptech.yourlearning.ibm.com/activity/URL-"+ props.activity_id + "?channelId=CNL_LCB_1602866246968"
  return <Button className="top" kind="tertiary" renderIcon={Launch20} 
  onClick={() => openInNewTab(url)}>Acesse as instruções para jogar</Button>
}

const Games = () => {
  return (
    <>
      <DefaultHeader />
      <div className="headerSpace">
        <LeadSpace
          type="default"
          theme="white"
          title="Olá professor(a)!"
          copy="Esse é o seu kit de atividades. Escolha por qual dos jogos/atividades deseja começar para ser direcionado(a) ao ambiente de instruções e acesso à plataforma interativa. Divirtam-se!"
          gradient={false}
          image={{
            sources: [
              { src: require('../../images/bg01.png').default, breakpoint: 'md' },
            ],
            defaultSrc: require('../../images/ptech.svg').default,
            alt: 'lead space image',
          }}
        />
        <img className="ptech-img" alt="ptech" src={require('../../images/ptech.svg').default} />
        <section>
          <div className="gameHolder">
            <div className="gameImg">
              <img src={require('../../images/game01.png').default} alt="IMG01" />
            </div>
            <div className="gameDescription">
              <p className="gameTitle">
                Seja o Robô
            </p>
              <p className="gameSinopse">
                Já imaginou como deve ser a realidade de um robô que atende humanos? Descubra agora mesmo com essa simulação onde o robô é você!
              </p>
              <>
                <Tag size="sm"type="blue" title="Clear Filter">Inteligência conversacional</Tag>
                {/* <Tag size="sm"type="blue" title="Clear Filter">Bots</Tag> */}
                {/* <Tag size="sm"type="blue" title="Clear Filter">Interação textual</Tag> */}
                <Tag size="sm"type="blue" title="Clear Filter">Processamento de linguagem natural</Tag>
              </>
              <br/>
              <>
                <Tag size="sm"type="teal" title="Clear Filter">Faixa etária: 6 anos+</Tag>
                <Tag size="sm"type="teal" title="Clear Filter">Duração: 10 mins</Tag>
              </>
              <div className="gameButton">
                <VideoButton youtube_id="Pk9SJMunIOc" />
                <SkillsBuildButton activity_id="9E631D108E3F" />
              </div>
              {primeiroAcesso}
            </div>
          </div>
          <hr className="onlyMobile" />
          <div className="gameHolder">
            <div className="gameDescription">
              <p className="gameTitle">
                Meu Robô Viu
            </p>
              <p className="gameSinopse">
                Você sabe como os robôs enxergam? Se coloque no lugar de um deles, utilizando apenas palavras para se expressar neste jogo de cartas com diversas variantes.
              </p>
              <>
                <Tag size="sm"type="blue" title="Clear Filter">Reconhecimento Visual</Tag>
                <Tag size="sm"type="blue" title="Clear Filter">Processamento de Imagens</Tag>
              </>
              <br/>
              <>
                <Tag size="sm"type="teal" title="Clear Filter">Faixa etária: 8 anos+</Tag>
                <Tag size="sm"type="teal" title="Clear Filter">Duração: 30 mins</Tag>
              </>
              <div className="gameButton">
                <VideoButton youtube_id="_mh4sXbaDYw" />
                <SkillsBuildButton activity_id="B401BC188294" />
              </div>
              {primeiroAcesso}
            </div>
            <div className="gameImg">
              <img src={require('../../images/game02.png').default} alt="IMG02" />
            </div>
          </div>
          <hr className="onlyMobile" />
          <div className="gameHolder">
            <div className="gameImg">
              <img src={require('../../images/game03.png').default} alt="IMG03" />
            </div>
            <div className="gameDescription">
              <p className="gameTitle">
                Titiríti
            </p>
              <p className="gameSinopse">
                Descubra como inteligências artificiais tomam decisões e mudam o mundo em histórias onde quem decide o caminho é você!
              </p>
              <>
                <Tag size="sm"type="blue" title="Clear Filter">Good Tech</Tag>
                <Tag size="sm"type="blue" title="Clear Filter">Tomada de Decisão</Tag>
                <Tag size="sm"type="blue" title="Clear Filter">Aprendizado de Máquinas</Tag>
              </>
              <br/>
              <>
                <Tag size="sm"type="teal" title="Clear Filter">Faixa etária: 6 anos+</Tag>
                <Tag size="sm"type="teal" title="Clear Filter">Duração: 10 mins</Tag>
              </>
              <div className="gameButton">
                <VideoButton youtube_id="EqCEFDNGx0s" />
                <SkillsBuildButton activity_id="C3346FFF7EB0" />
              </div>
              {primeiroAcesso}
            </div>
          </div>
          <hr className="onlyMobile" />
          <div className="gameHolder">
            <div className="gameDescription">
              <p className="gameTitle">
                Que Robô Sou Eu?
              </p>
              <p className="gameSinopse">
                Utilize cartas para aprender sobre temas diversos enquanto tenta descobrir a palavra secreta do seu robô antes dos demais participantes!
              </p>
              <>
                <Tag size="sm"type="blue" title="Clear Filter">Aprendizado de Máquinas</Tag>
                <Tag size="sm"type="blue" title="Clear Filter">Linguagem</Tag>
              </>
              <br/>
              <>
                <Tag size="sm"type="teal" title="Clear Filter">Faixa etária: 8 anos+</Tag>
                <Tag size="sm"type="teal" title="Clear Filter">Duração: 30 mins</Tag>
              </>
              <div className="gameButton">
                <VideoButton youtube_id="BrruSE2t0AM" />
                <SkillsBuildButton activity_id="5E19ADE6FAB4" />
              </div>
              {primeiroAcesso}
            </div>
            <div className="gameImg">
              <img src={require('../../images/game04.png').default} alt="IMG04" />
            </div>
          </div>
        </section>
        <section className="gamesFooter">
          <p className="finalSectionTitle">Explore os planos de aprendizagem da SkillsBuild</p>
          <div className="cardHolder">
            <div className="cardSize">
              <Card cta={cta01} {...card01} />
            </div>
            <div className="cardSpace"></div>
            <div className="cardSize">
              <Card cta={cta02} {...card02} />
            </div>
            <div className="cardSpace"></div>
            <div className="cardSize">
              <Card cta={cta03} {...card03} />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Games
